/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

ion-label {
  ion-icon {
    margin-bottom: -3px;
  }
}
.verde > .mdc-text-field--disabled.mdc-text-field--filled,
.blanco > .mdc-text-field--disabled.mdc-text-field--filled {
  padding: 0px 5px 0px 5px;
  background-color: transparent !important;
}
.mat-mdc-tab-header {
  background-color: white !important;
}
.mat-form-field-label {
  color: white !important;
}

.mat-form-field-underline {
  background-color: white !important;
}
.verticalEndRight {
  display: flex;
  align-items: flex-end;
  justify-content: right;
}
.verticalEndLeft {
  display: flex;
  align-items: flex-end;
  justify-content: left;
}
.mat-form-field-ripple {
  background-color: white !important;
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding: 0 !important;
}

.modal-wrapper.sc-ion-modal-md,
.modal-shadow.sc-ion-modal-md {
  background: transparent;
  box-shadow: none;
  -webkit-box-shadow: none;
}
.modal-cesion {
  --min-height: 600px;
}

.ion-color-subasta-header {
  --ion-color-base: #3b3f51;
  --ion-color-base-rgb: 85, 171, 243;
  --ion-color-contrast: #aeb2c4;
  --ion-color-contrast-rgb: 0, 0, 0;
  --ion-color-shade: #003c8f;
  --ion-color-tint: #002f6c;
}

.ion-color-subasta-header2 {
  --ion-color-base: #1a237e;
  --ion-color-base-rgb: 26, 35, 126;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 0, 0, 0;
  --ion-color-shade: #002f6c;
  --ion-color-tint: #002f6c;
}

.ion-color-subasta-header3 {
  --ion-color-base: #534bae;
  --ion-color-base-rgb: 83, 75, 174;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 0, 0, 0;
  --ion-color-shade: #7e57c2;
  --ion-color-tint: #b39ddb;
}

.ion-color-subasta-header4 {
  --ion-color-base: #37474f;
  --ion-color-base-rgb: 55, 71, 79;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 38, 50, 56;
  --ion-color-shade: #455a64;
  --ion-color-tint: #263238;
}

.ion-color-subasta-header5 {
  --ion-color-base: #455a64;
  --ion-color-base-rgb: 69, 90, 100;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 38, 50, 56;
  --ion-color-shade: #718792;
  --ion-color-tint: #1c313a;
}

.ion-color-subasta-grey-back {
  --ion-color-base: #b0bec5;
  --ion-color-base-rgb: 83, 75, 174;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 38, 50, 56;
  --ion-color-shade: #cfd8dc;
  --ion-color-tint: #b0bec5;
}

.toast-scheme {
  text-align: left;
  font-family: "Open Sans", sans-serif;
  font-size: 2.5vh;
  --border-radius: 15px;
  // width: 55%;

  --background: #ffa420;
  color: black;
  font-weight: 600;
}

::ng-deep .toast-content {
  --padding: 5px;
  padding: 5px;
}

.mat-mdc-menu-item {
  padding: 0 !important;
}

.person-menu-header.mat-mdc-menu-item {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.custom-alert-class .alert-wrapper {
  background-color: #3b3f51;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-size: 26px;
  font-weight: 400;
  direction: ltr;
  --border-radius: 15px;
  padding:0;

  .alert-button-group {
    justify-content: center;
    padding: 0;
  }
}
.alert-title.sc-ion-alert-md{
    color: white !important;
    padding-left: 5px;
}
.alert-head {
  padding: 0 !important;
  background-color: #3b3f51;

}

.alertImageContainer {
  background-color: #3b3f51;
  color: white;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.alertImage {
  width: 100px;
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;

}

.custom-alert-class .cancelarButton {
  background-color: #d84a38;
  color: white;
  box-shadow: 1px 2px 3px #8f7d7d;
  font-weight: 600;
  flex: 1;
  margin: 0;
}

.custom-alert-class .modal-wrapper {
  background-color: yellow;
}

.mensaje {
  font-size: 2.5vh;
  font-weight: 600;
}

.vertical {
  display: flex;
  align-items: center;
  justify-content: center;
}

.verticalStart {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.verticalRight {
  display: flex;
  align-items: center;
  justify-content: right;
}

.verticalLeft {
  display: flex;
  align-items: center;
  justify-content: left;
}

.verticalEnd {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.custom-alert-class .confirmarButton {
  background-color: #35aa47;
  color: white;
  box-shadow: 1px 2px 3px #8f7d7d;
  font-weight: 600;
  flex: 1;
  margin: 0;
}

.custom-alert-class .okButton {
  padding: 15px 15px 15px 15px;
  background-color: rgb(54, 128, 141);
  // border: 1px solid rgb(35, 54, 82);
  border-radius: 5px;
  color: white;
  box-shadow: 1px 2px 3px #8f7d7d;
  width: 100%;
}

.custom-alert-class .icon {
  font-size: 20vh;
}

.custom-dialog-container .mat-dialog-container {
  padding: 5px;
}

.custom-alert-class .cant {
  color: black;
  font-weight: bold;
}

.alert-btn {
  width: 45%;
}

.alert-center-btns * > span.alert-button-inner {
  // Centrado de texto de botones de alerts
  display: flex;
  align-items: center;
  justify-content: center;
}

.filtromodal .modal-wrapper {
  height: 350px;
  border-radius: 10px;
  width: 75%;
}

ion-backdrop {
  --backdrop-opacity: 0.8;
}

.swiper-pagination-bullet {
  margin: 0 1px;
  border: 2px solid black;
  background-color: white;
  height: 10px;
  width: 10px;
}

.swiper-pagination-bullets {
  bottom: -3px !important;
}

.inputContrasenha {
  /*Colores para cursor y borde inferior de inputs cambio de contraseña*/
  --border-color: var(--ion-color-primary) !important;
  --border-color-invalid: var(--ion-color-primary) !important;
  --border-color-focused: var(--ion-color-primary) !important;
  --highlight-color: var(--ion-color-primary) !important;
  --highlight-color-invalid: var(--ion-color-primary) !important;
  --highlight-color-focused: var(--ion-color-primary) !important;
  border-bottom: 1px solid grey;
  margin-bottom: 5px;
  min-height: 37px !important;
}

.inputContrasenha:focus::part(native) {
  outline: none;
}

.inputContrasenha:valid::part(native),
.inputContrasenha:invalid::part(native) {
  background-image: none;
}

.modal-full-screen {
  --width: 100%;
  --height: 100%;
}

.menuImg {
  padding: 10px;
  height: 100px;
}

.person-menu-header {
  border-bottom: 1px solid #808080;
  box-shadow: 0px 2px 3px #9e9d9d;
}

.person-menu-option {
  font-size: 15px;
}

.mat-menu-content {
  padding: 0 !important;
}

.icons {
  margin-right: 5px;
}

@media (min-width: 300px) or (max-width: 300px) {
  .icons {
    font-size: 15px;
  }

  .font-title {
    font-size: 14px;
  }

  .font-small {
    font-size: 10px;
  }

  .font-medium {
    font-size: 11px;
  }

  .font-big {
    font-size: 16px;
  }

  //Icono del usuario en las cabeceras
  .person-icon {
    font-size: 18px;
  }

  //Nombre del usuario en las cabeceras
  .person-name {
    font-size: 13px;
  }

  .wifiSize {
    font-size: 25px;
  }
}

@media (min-width: 350px) {
  .icons {
    font-size: 15px;
  }

  .font-title {
    font-size: 15px;
  }

  .font-small {
    font-size: 12px;
  }

  .font-medium {
    font-size: 14px;
  }

  .font-big {
    font-size: 19px;
  }

  //Icono del usuario en las cabeceras
  .person-icon {
    font-size: 20px;
  }

  //Nombre del usuario en las cabeceras
  .person-name {
    font-size: 15px;
  }

  .wifiSize {
    font-size: 30px;
  }
}

@media (min-width: 400px) {
  .icons {
    font-size: 17px;
  }

  .font-title {
    font-size: 17px;
  }

  .font-small {
    font-size: 14px;
  }

  .font-medium {
    font-size: 16px;
  }

  .font-big {
    font-size: 22px;
  }

  //Icono del usuario en las cabeceras
  .person-icon {
    font-size: 20px;
  }

  //Nombre del usuario en las cabeceras
  .person-name {
    font-size: 15px;
  }

  .wifiSize {
    font-size: 30px;
  }
}

@media (min-width: 450px) {
  .icons {
    font-size: 17px;
  }

  .font-title {
    font-size: 18px;
  }

  .font-small {
    font-size: 12px;
  }

  .font-medium {
    font-size: 16px;
  }

  .font-big {
    font-size: 22px;
  }

  //Icono del usuario en las cabeceras
  .person-icon {
    font-size: 20px;
  }

  //Nombre del usuario en las cabeceras
  .person-name {
    font-size: 15px;
  }

  .wifiSize {
    font-size: 30px;
  }
}

//DISPOSITIVOS GRANDES || NAVEGADOR-ESCRITORIO
@media (min-width: 500px) {
  .icons {
    font-size: 17px;
  }

  .font-title {
    font-size: 22px;
  }

  .font-small {
    font-size: 14px;
  }

  .font-medium {
    font-size: 16px;
  }

  .font-big {
    font-size: 26px;
  }

  //Icono del usuario en las cabeceras
  .person-icon {
    font-size: 25px;
  }

  //Nombre del usuario en las cabeceras
  .person-name {
    font-size: 20px;
  }

  .wifiSize {
    font-size: 30px;
  }
}

@media (min-width: 300px) and (max-width: 337px) {
  .special-text {
    // Texto boton parada previa listado subasta
    font-size: 8px;
  }
}

@media (min-width: 350px) and (max-width: 370px) {
  .special-text {
    // Texto boton parada previa listado subasta
    font-size: 10px;
  }
}

/*CONTROL DEL FORMULARIO EN LOGIN, RECUPERACION DE CONTRASEÑA Y CAMBIO DE COMTRASEÑA*/
@media (max-height: 610px) {
  .headerImg {
    height: 50px;
  }

  .tarjet {
    padding: 10px;
  }
}

@media (min-height: 610px) and (max-height: 645px) {
  .headerImg {
    height: 70px;
  }

  .tarjet {
    padding: 20px;
  }
}

@media (min-height: 645px) {
  .headerImg {
    height: 100px;
  }

  .tarjet {
    padding: 20px;
  }
}

.tarjet {
  border-radius: 10px;
  box-shadow: 0px 4px 12px #3d4044;
  background-color: white;
  max-width: 369px !important;
}

.menusuperior {
  min-width: 152px !important;
}

.modal-selector-class {
  --height: 100%;
  --width: 100%;
}

// Inputs de modal selector de partidas
ion-input.custom-input > .native-input[disabled].sc-ion-input-md {
  opacity: 1 !important;
}

.no-display {
  // Clase para esconder elementos
  display: none;
}

.mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: white;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent !important;
}

::ng-deep .mat-mdc-form-field-underline {
  display: none;
}
.mat-mdc-text-field-wrapper {
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0);
  // outline: #A1A5B1 solid 1px;
  border-radius: 5px !important;
}

.password-class {
  // Clase para mostrar asteriscos en diversos puntos de la app
  -webkit-text-security: disc;
  -mox-text-security: disc;
  font-family: "text-security-disc";
}

.bg-transparent {
  background-color: transparent;
}

.bg-content {
  --background: #7194a5;
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

.no-margin {
  margin: 0px;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border: none !important;
}
